<template>
    <div>
        <div class="vx-row">
            <AllSmsCard :data="texts"></AllSmsCard>
        </div>
    </div>
</template>
]

<script>
import { http } from '@/services'

import AllSmsCard from './Cards/AllSms'

export default {
    name: 'Sms',
    props: [],
    components: {
        AllSmsCard,
    },

    mounted() {
        this.textsFetch()
    },

    data() {
        return {
            texts: null,
        }
    },
    methods: {
        textsFetch() {
            http.get('sms_site')
                .then((response) => (this.texts = response.data))
                .catch((error) => console.log(error))
        },
    },
}
</script>
