<template>
    <vx-card search>
        <vs-table
            v-if="texts"
            v-model="selected"
            @selected="gotoSelected"
            search
            :data="texts"
        >
            <template slot="thead">
                <vs-th sort-key="description">Category</vs-th>
                <vs-th sort-key="title">Title</vs-th>
                <vs-th sort-key="sent_when">Sent On</vs-th>
                <vs-th sort-key="description">Description</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].category">
                        {{ data[indextr].category }}
                    </vs-td>
                    <vs-td :data="data[indextr].title">
                        {{ data[indextr].title }}
                    </vs-td>
                    <vs-td :data="data[indextr].sent_when">
                        {{ data[indextr].sent_when }}
                    </vs-td>
                    <vs-td :data="data[indextr].description">
                        {{ data[indextr].description }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </vx-card>
</template>

<script>
export default {
    name: 'AllSmsCard',
    props: ['data'],
    watch: {
        data: {
            immediate: true,
            deep: true,
            handler(value) {
                this.texts = value
            },
        },
    },
    data() {
        return {
            texts: null,
            selected: null,
        }
    },
    methods: {
        gotoSelected(tr) {
            this.$router.push({ name: 'Text', params: { pk: tr.id } })
        },
    },
}
</script>

<style></style>
